.environment {
  display: inline-flex;
  align-items: baseline;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
}

.environment .color {
  align-self: center;
  margin-inline-end: var(--lp-size-4);
}

.environment .display {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
