.ListBox {
  min-width: var(--lp-size-256);
  max-width: var(--lp-size-400);
}

.Flag {
  display: grid;
  grid-template-areas: 'name';
  grid-template-columns: 1fr;
  gap: var(--lp-size-10);
  justify-items: start;
  align-items: center;
  width: 100%;
}

.Flag:has(.Status) {
  grid-template-areas: 'status name';
  grid-template-columns: var(--lp-size-16) 1fr;
}

.Flag:has(.Targeting) {
  grid-template-areas: 'name targeting';
  grid-template-columns: 1fr auto;
}

.Flag:has(.Status):has(.Targeting) {
  grid-template-areas: 'status name targeting';
  grid-template-columns: var(--lp-size-16) 1fr auto;
}

.Status {
  grid-area: status;
}

.Name {
  grid-area: name;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Targeting {
  position: relative;
  grid-area: targeting;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: var(--lp-text-label-2-medium);
  height: var(--lp-size-20);
  padding: var(--lp-spacing-100) var(--lp-spacing-200);
  border-radius: var(--lp-border-radius-medium);
  color: var(--lp-color-text-ui-secondary);
  background-color: var(--lp-color-bg-ui-tertiary);
}

.Targeting::after {
  content: 'Off';
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
}

.Targeting[data-on] {
  color: var(--lp-color-text-feedback-success);
  background-color: var(--lp-color-bg-feedback-success);
}
