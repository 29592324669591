.SkipToContent {
  z-index: var(--stacking-skip-to-content);
  color: #0000;
  background-color: #0000;
  padding: .625rem;
  position: absolute;
  top: 0;
  left: -1000px;
}

.SkipToContent:focus {
  background-color: var(--lp-color-bg-feedback-info);
  color: var(--lp-color-text-ui-primary-base);
  left: 0;
}
