.marker {
  flex-shrink: 0;
  display: inline-block;
  width: var(--lp-size-12);
  height: var(--lp-size-12);
  border-radius: var(--lp-border-radius-medium);
  background-color: var(--marker-color, currentColor);
  box-shadow: inset 0px 0px 0px 1px
    color-mix(in srgb, var(--lp-color-text-ui-primary-inverted) 10%, rgba(0, 0, 0, 0.25));
}
