.ListBox {
  min-width: var(--lp-size-256);
  max-width: var(--lp-size-400);
}

.Variation {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  width: 100%;
}
