.colors {
  white-space: nowrap;
  flex-shrink: 0;
  display: inline-flex;
}

.colors .color.tiny:not(:first-child) {
  margin-left: -.3125rem;
}

.colors .color.small:not(:first-child) {
  margin-left: -.5625rem;
}

.colors .color.medium:not(:first-child) {
  margin-left: -.75rem;
}

.colors .color.large:not(:first-child) {
  margin-left: -1.5rem;
}

.colors .remaining {
  color: var(--lp-color-text-ui-secondary);
  font: var(--lp-text-small-1-regular);
  margin-inline-start: var(--lp-spacing-200);
}
