.SkipToContent {
  background-color: transparent;
  z-index: var(--stacking-skip-to-content);
  color: transparent;
  position: absolute;
  top: 0;
  padding: 0.625rem;
  left: -1000px;
}

.SkipToContent:focus {
  background-color: var(--lp-color-bg-feedback-info);
  color: var(--lp-color-text-ui-primary-base);
  left: 0;
}
