.colors {
  display: inline-flex;
  white-space: nowrap;
  flex-shrink: 0;
}

.colors .color.tiny:not(:first-child) {
  margin-left: -0.3125rem;
}

.colors .color.small:not(:first-child) {
  margin-left: -0.5625rem;
}

.colors .color.medium:not(:first-child) {
  margin-left: -0.75rem;
}

.colors .color.large:not(:first-child) {
  margin-left: -1.5rem;
}

.colors .remaining {
  margin-inline-start: var(--lp-spacing-200);
  color: var(--lp-color-text-ui-secondary);
  font: var(--lp-text-small-1-regular);
}
