.ListBox {
  min-width: var(--lp-size-256);
  max-width: var(--lp-size-400);
}

.Flag {
  gap: var(--lp-size-10);
  grid-template-columns: 1fr;
  grid-template-areas: "name";
  place-items: center start;
  width: 100%;
  display: grid;
}

.Flag:has(.Status) {
  grid-template-areas: "status name";
  grid-template-columns: var(--lp-size-16) 1fr;
}

.Flag:has(.Targeting) {
  grid-template-columns: 1fr auto;
  grid-template-areas: "name targeting";
}

.Flag:has(.Status):has(.Targeting) {
  grid-template-areas: "status name targeting";
  grid-template-columns: var(--lp-size-16) 1fr auto;
}

.Status {
  grid-area: status;
}

.Name {
  text-overflow: ellipsis;
  grid-area: name;
  overflow: hidden;
}

.Targeting {
  font: var(--lp-text-label-2-medium);
  height: var(--lp-size-20);
  padding: var(--lp-spacing-100) var(--lp-spacing-200);
  border-radius: var(--lp-border-radius-medium);
  color: var(--lp-color-text-ui-secondary);
  background-color: var(--lp-color-bg-ui-tertiary);
  flex-direction: column;
  grid-area: targeting;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.Targeting:after {
  content: "Off";
  visibility: hidden;
  user-select: none;
  pointer-events: none;
  height: 0;
  overflow: hidden;
}

.Targeting[data-on] {
  color: var(--lp-color-text-feedback-success);
  background-color: var(--lp-color-bg-feedback-success);
}
